.container {
  position: relative;
  top: 170px;
  opacity: 0;
  transition: opacity 1.5s ease;
  animation: slideInFromLeft 1.5s forwards;
}

.subContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 4vh 8vw;
  padding: 30px;
  box-shadow: 3px 5px 10px #0000007d;
  border-radius: 5px;
  background-color: #fff;
}

.recoverContainer {
  padding: 5px;
  border: 2px solid #7a7a7a;
  border-radius: 5px;
}

.btnContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

@media only screen and (min-width: 1024px) {
  .container {
    top: 150px;
  }

  .subContainer {
    width: 35%;
    max-width: 600px;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    margin: 10vh auto;
  }

  .title {
    width: 200px;
    margin: 0 51vw;
  }

  .recoverContainer {
    min-width: 200px;
    padding: 20px;
    border: 2px solid #7a7a7a;
    border-radius: 5px;
  }
}

@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-150px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
