.container {
  position: relative;
  top: 170px;
  opacity: 0;
  animation: slideInFromLeft 1s ease-in-out forwards;
}

.subContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 4vh 8vw;
  padding: 20px;
  box-shadow: 3px 5px 10px #0000007d;
  border-radius: 5px;
  background-image: linear-gradient(rgba(255, 255, 255, 0.8) 44%, rgba(255, 255, 255, 0.8) 58%),
    url('../../../../public/assets/images/urquizaSchool.jpg');
  background-position: top;
  background-size: cover;
}

.loginContainer {
  padding: 10px;
  border: 2px solid #7a7a7a;
  border-radius: 5px;
}

.btnContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.password:link,
.password:visited {
  margin-top: 20px;
  color: #000;
  text-align: center;
  font-weight: 500;
  text-decoration: none;
}

.password:hover {
  text-shadow: 2px 4px 5px #000;
  transition: text-shadow 0.1s ease-in-out;
}

.passwordContainer {
  position: relative;
  width: 100%;
}

.showPasswordIcon {
  position: absolute;
  right: 15px;
  top: 38px;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 1.5s ease;
  animation: showPassword 1.5s forwards;
}

@media only screen and (min-width: 1024px) {
  .container {
    top: 150px;
  }

  .subContainer {
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    margin: 10vh 48px 0 347px;
    padding: 4vh;
    background-image: linear-gradient(
        to right,
        rgb(255, 255, 255) 44%,
        rgba(255, 255, 255, 0.3) 58%
      ),
      url('../../../../public/assets/images/urquizaSchool.jpg');
    background-position: right;
    background-size: contain;
  }

  .title {
    width: 200px;
    margin: 0 51vw;
  }

  .img {
    width: 50%;
  }

  .loginContainer {
    width: 40%;
    min-width: 200px;
    padding: 10px;
    border: 2px solid #7a7a7a;
    border-radius: 5px;
  }

  .showPasswordIcon {
    top: 45px;
    font-size: 24px;
  }
}

@keyframes showPassword {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-150px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
