.button {
  padding: 6px 15px;
  border-radius: 5px;
  border: none;
  box-shadow: 3px 4px 5px #000;
  font-size: 15px;
  font-weight: 600;
}

.cancelBtn {
  background-color: rgb(212, 30, 30);
  color: #fff;
}

.cancelBtn:hover {
  background-color: rgb(130, 19, 19);
  color: #fff;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
}

.submitBtn {
  background-color: #757474;
  color: #fff;
}

.submitBtn:hover {
  background-color: #555454;
  color: #fff;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
}

.xBtn {
  position: relative;
  top: 2px;
  border: none;
  font-size: 17px;
  font-weight: 700;
  color: #000;
  background-color: transparent;
}

.xBtn:hover {
  border-radius: 5px;
  transform: scale(1.2);
  transition: transform 0.2s ease-in;
  cursor: pointer;
}

.editBtn {
  background-color: #22304a;
  color: #fff;
}

.editBtn:hover {
  background-color: #374e77;
  color: #fff;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
}
