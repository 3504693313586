.container {
  position: relative;
  top: 190px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 450px;
}

.tableContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 10px 20px;
  overflow-y: auto;
  border-radius: 8px;
  box-shadow: 3px 5px 10px #000000cc;
  background-color: #fff;
}

.tableContainer::-webkit-scrollbar {
  width: 10px;
  background-color: #757474;
  border-radius: 0 5px 5px 0;
}

.tableContainer::-webkit-scrollbar-thumb {
  background-color: #000;
  border-radius: 5px;
}

.tableContainer::-webkit-scrollbar-thumb:hover {
  background-color: #282828;
}

.tableContainer::-webkit-scrollbar-track {
  background-color: none;
}

.contTable {
  border-collapse: collapse;
  color: #000;
}

.theadTable {
  background-color: #757474;
  color: #fff;
  position: sticky;
  top: 0;
}

.thTable {
  padding: 10px;
  white-space: pre-wrap;
}

.theadTable,
.thTable {
  padding: 15px 10px;
  text-align: center;
}

.headers {
  padding: 15px 10px;
}

.borderLeft {
  border-radius: 8px 0px 0px 0px;
}

.borderRight {
  border-radius: 0px 8px 0px 0px;
}

.tbody {
  background-color: #ffffffed;
  font-style: italic;
}

.rows {
  border-bottom: 2px solid #ddd;
}

.rows:hover {
  background-color: aliceblue;
}

.delete {
  margin: 5px 10px;
  color: rgb(180, 11, 11);
  font-size: 28px;
  background-color: #e4e4e4;
  box-shadow: 1px 3px 5px #0000007d;
  cursor: pointer;
}

.check {
  margin: 0 10px 5px 10px;
  background-color: #e4e4e4;
  box-shadow: 1px 3px 5px #0000007d;
  color: rgb(25, 141, 40);
  font-size: 28px;
  cursor: pointer;
}

.info {
  text-align: center;
}

.filter {
  width: 80%;
  min-width: 60px;
  height: 22px;
  border-radius: 3px;
  outline: none;
  box-shadow: 3px 3px 3px #000000a2;
}

.filter:focus {
  outline: none;
  border-color: aliceblue;
  background-color: aliceblue;
  box-shadow: inset 2px 2px 3px #000000a2;
}

.photoContainer {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 4px 2px 4px rgba(0, 0, 0, 0.5);
  background-color: white;
}

.profilePhoto {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

@media only screen and (min-width: 1024px) {
  .container {
    top: 150px;
  }
  .tableContainer {
    margin: 10vh 110px 0 347px;
  }
}
