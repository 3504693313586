.container {
  position: relative;
  top: 170px;
}

.subContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 4vh 8vw;
  padding: 30px;
  box-shadow: 3px 5px 10px #0000007d;
  border-radius: 5px;
  background-color: #fff;
}

.recoverContainer {
  width: 100%;
  border: 2px solid #7a7a7a;
  border-radius: 5px;
}

.btnContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0 10px;
}

.passwordContainer {
  position: relative;
  width: 100%;
}

.showPasswordIcon {
  position: absolute;
  right: 15px;
  top: 100px;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 1.5s ease;
  animation: showPassword 1.5s forwards;
}

.showPasswordIcon2 {
  position: absolute;
  right: 15px;
  top: 56px;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 1.5s ease;
  animation: showPassword 1.5s forwards;
}

@media only screen and (min-width: 1024px) {
  .container {
    top: 150px;
  }

  .subContainer {
    width: 50%;
    max-width: 600px;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    margin: 6vh 30%;
  }

  .recoverContainer {
    padding: 5px 15px;
  }

  .title {
    width: 200px;
    margin: 0 51vw;
  }

  .showPasswordIcon {
    top: 87px;
    font-size: 24px;
  }

  .showPasswordIcon2 {
    top: 60px;
    font-size: 24px;
  }
}

@keyframes showPassword {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
